@-ms-viewport {
  width: device-width;
}

html {
  font-size: 0.875rem;
  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }
}

*:focus {
  outline: 0 !important;
}

.iossc {
  -webkit-overflow-scrolling: touch;
}

.pointer {
  cursor: pointer;
}

.font-weight-heavy {
  font-weight: 600;
}

.mui-link-button {
  &:hover {
    color: white;
  }
}

.mui-label-center {
  width: fit-content;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.mui-label-center-shrink {
  transform: translate(0, 0) scale(0.75);
  transform-origin: top center;
}

.text-blackjack {
  font-family: BlackJack, cursive;
}

.bg-body {
  background-color: $body-bg;
}
.bg-yellow {
  background-color: #f5cc45;
}
.text-px-blue {
  color: #468ea0;
}
.text-px-green {
  color: #65be9f;
}
.text-px-orange {
  color: #fc960a;
}
.text-px-yellow {
  color: #f5cc45;
}
.w-mx100 {
  max-width: 100vw;
}

@import "./mixins.scss";

.container {
  @include px-responsive-paddings(
    (
      left: 2,
      right: 2,
    )
  );
}
.container.no-gutters,
.container-fluid.no-gutters {
  margin: auto;
  @include px-responsive-paddings(
    (
      left: 0,
      right: 0,
    )
  );
}
.container.half-gutters,
.container-fluid.half-gutters {
  margin: auto;
  @include px-responsive-paddings(
    (
      left: 4,
      right: 4,
    )
  );
}

.mt-neg-8 {
  @include px-responsive-margins(
    (
      top: -1,
    )
  );
}
.mt-neg-6 {
  @include px-responsive-margins(
    (
      top: -2,
    )
  );
}
.mt-neg-4 {
  @include px-responsive-margins(
    (
      top: -4,
    )
  );
}
.mt-neg-2 {
  @include px-responsive-margins(
    (
      top: -6,
    )
  );
}
.mt-neg-1 {
  @include px-responsive-margins(
    (
      top: -8,
    )
  );
}

.row.py-half {
  @include px-responsive-paddings(
    (
      top: 3,
    )
  );
  > .col,
  > [class*="col-"] {
    @include px-responsive-paddings(
      (
        left: 4,
        right: 4,
        bottom: 3,
      )
    );
  }
}

.row {
  @include px-responsive-margins(
    (
      left: -4,
      right: -4,
    )
  );
  @include px-responsive-paddings(
    (
      top: 2,
    )
  );
  &.with-bottom-padding {
    @include px-responsive-paddings(
      (
        bottom: 2,
      )
    );
  }
  > .col,
  > [class*="col-"] {
    @include px-responsive-paddings(
      (
        left: 4,
        right: 4,
        bottom: 2,
      )
    );
  }
}

.row.no-gutters {
  @include px-responsive-margins(
    (
      left: 0,
      right: 0,
    )
  );
  @include px-responsive-paddings(
    (
      top: 0,
    )
  );
  > .col,
  > [class*="col-"] {
    @include px-responsive-paddings(
      (
        left: 0,
        right: 0,
        bottom: 0,
      )
    );
  }
}

.row.slick-slider {
  margin-bottom: -6px;
  @include px-responsive-margins(
    (
      left: 0,
      right: 0,
    )
  );
  @include px-responsive-paddings(
    (
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    )
  );
  &.has-title {
    @include px-responsive-paddings(
      (
        top: 2,
      )
    );
  }
  &.nonE2E {
    @include px-responsive-paddings(
      (
        top: 2,
        bottom: 2,
      )
    );
  }
  &.has-dots {
    padding-bottom: 30px !important;
  }
  & .col,
  & [class*="col-"] {
    @include px-responsive-paddings(
      (
        left: 4,
        right: 4,
        bottom: 0,
      )
    );
    @include px-responsive-margins(
      (
        left: 0,
        right: 0,
      )
    );
    &.singular {
      @include px-responsive-paddings(
        (
          left: 2,
          right: 2,
        )
      );
      @include px-responsive-margins(
        (
          left: 0,
          right: 0,
        )
      );
      &.E2E {
        @include px-responsive-paddings(
          (
            left: 0,
            right: 0,
          )
        );
      }
    }
  }
  & .free-slider {
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    @include no-scroll-bar();
    @include px-responsive-paddings(
      (
        left: 4,
        right: 4,
      )
    );
    & .col,
    & [class*="col-"] {
      @include px-responsive-paddings(
        (
          left: 4,
          right: 4,
          bottom: 0,
        )
      );
      @include px-responsive-margins(
        (
          left: 0,
          right: 0,
        )
      );
    }
  }
}

.white-overlay {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

nav.drawer {
  > a {
    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: transparent;
    > i.icon {
      color: gray;
    }
    svg.arr {
      opacity: 1;
    }
    svg.open {
      color: gray;
    }
    svg.close {
      color: $primary;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.05);
      svg.arr {
        color: $primary;
      }
    }
    span {
      color: white;
      font-size: 0.95rem;
    }
    &.part-active {
      border-left-color: rgba(255, 255, 255, 0.3);
    }
    &.active {
      border-left-color: $primary;
      background: rgba(255, 255, 255, 0.1);
      > i.icon {
        color: $primary;
      }
      span {
        color: $primary;
        font-weight: bold;
      }
    }
  }
}
.dealer__status {
  display: inline-block;
  text-align: center;
  min-width: 100px;
}

.status_col_80 {
  display: inline-block;
  text-align: center;
  min-width: 80px;
}

.pending__dealer__status {
  display: inline-block;
  text-align: center;
  width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.omf_transaction_status {
  display: inline-block;
  text-align: center;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cursor {
  cursor: pointer;
}

.light_row__background {
  background-color: rgba(255, 127, 127, 0.3) !important;
}

header.tcaAppBar {
  margin-left: $drawer-width;
  @include media-breakpoint-up(md) {
    width: calc(100% - #{$drawer-width});
  }
}

.tca_tabBar {
  width: 100vw;
  @include media-breakpoint-up(md) {
    width: calc(100vw - #{$drawer-width});
  }
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}

.task_container {
  width: 100vw;
  margin-left: 20px;
  margin-right: 20px;
  height: calc(100vh - #{$task_header_height} - #{$task_header_height});
  background-color: #fff;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  @include media-breakpoint-up(md) {
    width: calc(100vw - #{$drawer-width} - #{$task_margin_right});
  }
}

.task_column {
  width: 300px;
  height: 100%;
  background-color: #dfe1e6;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}
.task_column_body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.task_column_list {
  overflow: auto;
  flex: 1;
}

.notes__body {
  overflow-y: auto;
  height: 300px;
}

.arrow-left {
  position: relative;
  top: 2px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #cfd8dc;
}

.arrow-right {
  position: relative;
  top: 2px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #cfd8dc;
}

.msg-body {
  background-color: #cfd8dc;
  border-radius: 3px;
  padding: 5px 10px;
}

.msg-system-body {
  background-color: antiquewhite;
  border-radius: 10px;
  margin-bottom: 2px;
  overflow-wrap: break-word;
}

.segment-button {
  width: 150px;
}

#section-stepper-divider {
  width: 2px;
  position: absolute;
  height: calc(100% - 40px);
  left: 40px;
  background-color: white;
  position: absolute;
}

.rs-picker-daterange-menu {
  // color: red;
  z-index: 1300 !important  ;
}

.MuiModal-root {
  z-index: 1200 !important;
}

.custom-datepicker {
  width: 500px;
}

.rs-picker-daterange-header {
  display: none;
}
